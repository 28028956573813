export default {
    title: "Propriétés de {agencyName}",
    in: "dans",
    propertiesFound: "propriétés trouvées",
    toDay: "Aujourd'hui",
    days: "journées",
    week: "Semaine",
    year: "An",
    month: "Mois",
    prev: "Précédente",
    next: "Suivante",
    backToList: "Retour à la liste",
    sorry: "Désolé nous",
    cantFind: "ne trouve pas",
    lookingFor: "la page que vous recherchez ...",
    thankYou: "MERCI DE VISITER NOTRE SITE",
    pleaseContact: "Veuillez contacter l'agent pour une nouvelle URL.",
    ForSale: "VENTE",
    ShortTermRental: "Location courte",
    LongTermRental: "Loyer long",
    showMore: "Montre plus",
    showLess: "Montrer moins",
    price: "Prix",
    pricePer: "Prix par",
    built: "Construire",
    floorSpace: "Espace intérieur",
    terrace: "Terrasse",
    garden: "Jardin",
    plot: "Tracé",
    propTitle: '{subType} {searchType} in {location}',
    propTitleShort: '{subType} in {location}',
    notAvailable: "Non disponible",
    notFound: 'Page non trouvée',
    emptyProperties: "Aucune propriété n'a été trouvée, veuillez contacter votre agent pour plus d'informations.",
    linkExpired: "Oups, ce lien a expiré. Communiquez avec votre agent immobilier pour recevoir un lien immobilier mis à jour.",
    newDevelopment: "Nouveau développement",
    agencyRaiiNo: "Numéro d'enregistrement de l'agence",
    home: {
        verifyYourURLHere: "Vérifiez votre URL ici. Pour ce faire, connectez-vous à WhatsApp sur votre navigateur Web, puis copiez l'URL de WhatsApp ici.",
        pasteUrlHere: "Coller l'URL ici",
        verifyUrl: "Vérifier l'URL",
        whatIsPropertyViewer: "Qu'est-ce que Property-Viewer.com?",
        thisIsWebsiteIsUsed: "Ce site Web est utilisé par les agents immobiliers pour envoyer des propriétés à leurs clients. Il s'agit d'un produit de {resalesonlinecom} En cas de doute, veuillez vous adresser à l'agent qui vous a envoyé votre liste de propriétés.",
        howDoesItWork: "Comment cela fonctionne-t-il?",
        inThePast: "Dans le passé, les agents envoyaient des documents PDF à leurs clients. Cependant, ceux-ci ne sont pas faciles à afficher sur de nombreux appareils. Par conséquent, nous avons {propertyviewercom} afin que le client puisse plus facilement voir les propriétés qui leur sont envoyées.",
        createAShortList: "L’agent va créer une courte liste de propriétés. Quand ils partagent ces propriétés avec vous, vous recevrez un lien. En cliquant sur ce lien vous montrera les propriétés."
    },

    list: {
        propertyTitle: "Cliquez ici pour voir les informations pour cette propriété"
    },

    searchType: {
        ForSale: "à vendre",
        ShortTermRental: "à louer à court terme",
        LongTermRental: "à louer à long terme",
    },

    rsStatus: {
        Available: "Disponible",
        Listing: "Listage",
        UnderOffer: "Sous Offre",
        SaleAgreed: "Vente convenue",
        OffMarket: "Hors marché",
        Sold: "Vendu",
        Rented: "Loué"
    },

    details: {
        average: "Moyenne",
        averagePricesFor: "Prix moyens pour",
        showFullER: "Afficher complet",
        thisProperty: "Cette propriété",
        infoNotAvailable: "Ces informations ne sont pas disponibles.",
        feesEnergyRating: "Frais, Cote Énergétique",
        fees: "Frais",
        ibiFees: "Frais IBI",
        basuraTax: "Taxe Basura",
        communityFees: "Frais de communauté",
        associatedCosts: "Coûts associés",
        energyRating: "Classe énergétique",
        securityDeposit: "Dépôt de garantie",
        energyCertificate: "Certificat énergétique",
        energyCertificatePending: "Le certificat énergétique est en attente",
        energyConsumption: "Consommation d'énergie",
        co2: "CO₂",
        co2PerYear: "kgCO₂/m² par an",
        kwhPerYear: "kWh/m² par an",
        other: "Autrui",
        buildYear: "Année de construction",
        forSale: "À vendre",
        sold: "Vendu",
        longTerm: "Long terme",
        shortTermHigh: "Élevé à court terme",
        shortTermLow: "Bas à court terme",
        allFeatures: "Toutes les fonctionnalités",
        aproxLocation: "Aprox. Emplacement",
        listingAgent: "Agent inscripteur",
        notIncludeExpensesAndTaxes: "El precio no incluye gastos ni tributos. Los gastos adicionales al precio por parte del comprador son: Vivienda nueva/1ª Transmission Impuesto sobre el Valor Añadido (IVA) 10% (o 4% en caso de vivienda protegida); o en el caso de 2ª y/o Posteriores Transmisiones Impuesto Sobre Transmisiones (ITP) 7%. Además, la formalización en documento público del contrato de compraventa (escrituras) está sujeta a AJD Actos Jurídicos Documentados 1,2% sobre el precio de la compra, gastos de inscripción en el registro y notaría. Tiene a su disposición copia de la correspondiente ficha informativa de esta propiedad en nuestra oficina según Decreto 218/2005 de 11 de octubre.",
        rentalLicenseRef: "Licence de location Ref",
        agencyInformation: "Renseignements sur l’agence",
    },
};