import React from 'react';
import { useSelector } from "react-redux";
import { Row, Col } from 'react-bootstrap';

import Converter from '../../../constants/converters';

export default (props) => {
    const l = useSelector((state) => {
        return state.localizations.l;
    });

    const currentProperty = props.property;
    const unit = Converter.DIMENSION[currentProperty.Unit];

    return (
        <Row className="builts">
            { ((currentProperty.BuiltArea > 0 && currentProperty.BuiltAreaTo >= 0) || (currentProperty.BuiltArea === 0 && currentProperty.BuiltAreaTo > 0)) ?
                    (<Col>
                        <div className="text"><i className="icon icon-proe-built" /> { l.built }</div>
                        <div className="value">
                            <span className="number">
                                { currentProperty.BuiltArea.toFixed(0) }
                                { currentProperty.BuiltArea < currentProperty.BuiltAreaTo && " - " + currentProperty.BuiltAreaTo.toFixed(0) }
                            </span>
                            <span className="unit">{ unit }</span>
                        </div>
                    </Col>) : ''
            }

            { ((currentProperty.UsefulArea > 0 && currentProperty.UsefulAreaTo >= 0) || (currentProperty.UsefulArea === 0 && currentProperty.UsefulAreaTo > 0)) ?
                    (<Col>
                        <div className="text"><i className="icon icon-proe-built" /> { l.floorSpace }</div>
                        <div className="value">
                            <span className="number">
                                { currentProperty.UsefulArea.toFixed(0) }
                                { currentProperty.UsefulArea < currentProperty.UsefulAreaTo && " - " + currentProperty.UsefulAreaTo.toFixed(0) }
                            </span>
                            <span className="unit">{ unit }</span>
                        </div>
                    </Col>) : ''
            }

            { ((currentProperty.TerraceArea > 0 && currentProperty.TerraceAreaTo >= 0) || (currentProperty.TerraceArea === 0 && currentProperty.TerraceAreaTo > 0)) ?
                    (<Col>
                        <div className="text"><i className="icon icon-proe-terrace" /> { l.terrace }</div>
                        <div className="value">
                            <span className="number">
                                { currentProperty.TerraceArea.toFixed(0) }
                                { currentProperty.TerraceArea < currentProperty.TerraceAreaTo && " - " + currentProperty.TerraceAreaTo.toFixed(0) }
                            </span>
                            <span className="unit">{ unit }</span>
                        </div>
                    </Col>) : ''
            }

            { ((currentProperty.GardenPlotArea > 0 && currentProperty.GardenPlotAreaTo >= 0) || (currentProperty.GardenPlotArea === 0 && currentProperty.GardenPlotAreaTo > 0)) ?
                    (<Col>
                        <div className="text"><i className="icon icon-proe-garden" /> { l.garden }</div>
                        <div className="value">
                            <span className="number">
                                { currentProperty.GardenPlotArea.toFixed(0) }
                                { currentProperty.GardenPlotArea < currentProperty.GardenPlotAreaTo && " - " + currentProperty.GardenPlotAreaTo.toFixed(0) }
                            </span>
                            <span className="unit">{ unit }</span>
                        </div>
                    </Col>) : ''
            }
        </Row>
    );
}
